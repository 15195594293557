import React, { useState, useEffect, useReducer, useCallback } from 'react';
import classnames from 'classnames';
import PlacesAutocomplete from 'react-places-autocomplete';
import isEmpty from 'lodash.isempty';

import FontIcon from 'components/FontIcon';
import { handleError } from 'utils/common';

import styles from './GooglePlacesAutocomplete.scss';

// const getCurrentLatLong = () =>
//   new Promise((resolve, reject) => {
//     window.navigator.geolocation.getCurrentPosition((position) => {
//       const lat = position.coords.latitude;
//       const long = position.coords.longitude;

//       resolve([lat, long]);
//     }, reject);
//   });

const GooglePlacesAutocomplete = (props) => {
  const {
    className,
    value = '',
    name,
    placeholder,
    handleSelect,
    needReset,
    disabled,
    handleBlur,
    showCloseButton = false,
    searchOptions = {
      types: ['(cities)'],
    },
  } = props;

  const [error, setError] = useState(); // not in use at the moment, only Sentry reporting

  const [suggestions, setSuggestions] = useState([]);

  const [{ input, origValue, showErrors }, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'props-change': {
          const newState = { ...state };
          const { value: nextValue } = action;

          const hasErrors = !!error;

          if (nextValue !== input && nextValue !== origValue) {
            if (!hasErrors) {
              newState.showErrors = hasErrors;
            }

            newState.input = nextValue;
          }

          return newState;
        }
        case 'show-errors': {
          const { showErrors } = action;
          return { ...state, showErrors };
        }
        case 'input': {
          const { input } = action;
          return { ...state, input };
        }
      }
    },
    {
      input: value,
      origValue: props.value,
      showErrors: false,
    }
  );

  const setInput = useCallback(
    (input) =>
      dispatch({
        type: 'input',
        input,
      }),
    []
  );

  const setShowErrors = useCallback(
    (showErrors) => dispatch({ type: 'show-errors', showErrors }),
    []
  );

  useEffect(() => {
    dispatch({ type: 'props-change', value: props.value, errors: props.errors });
  }, [props.errors, props.value]);

  const handleSelfOnBlur = () => {
    setShowErrors(!!error);
  };

  const autocompleteProps = {
    value: !input || isEmpty(input) ? '' : input,
    highlightFirstSuggestion: true,
    debounce: 150,
    searchOptions,
    onSelect: (value) => {
      const selectedSuggestion = suggestions.find(({ description }) => value === description);

      const { placeId } = selectedSuggestion;

      const el = window.document.querySelector(`input[name="${name}"][type="text"]`);

      const placesService = new window.google.maps.places.PlacesService(el);

      placesService.getDetails({ placeId }, (data) => {
        const lat = data.geometry.location.lat();
        const lon = data.geometry.location.lng();

        handleSelect(value, lat, lon);
      });

      if (value === '' || !value.trim() || needReset) {
        setInput('');
      } else {
        setInput(value);
      }

      setError('');
    },
    onChange: (place) => {
      setInput(place);
    },
    onError: (err) => {
      console.log(err);

      if (err !== 'ZERO_RESULTS') {
        handleError(err);
      }
    },
  };

  return (
    <PlacesAutocomplete {...autocompleteProps}>
      {({ getInputProps, suggestions, getSuggestionItemProps }) => {
        setSuggestions(suggestions);

        return (
          <div className={classnames(styles.root, className)}>
            {showErrors && <span className={styles.error}>{error}</span>}
            <input
              {...getInputProps({
                className: classnames(styles.input, {
                  [styles.hasError]: showErrors,
                  [styles.withCloseButton]: showCloseButton,
                }),
                name,
                placeholder,
                disabled,
                onBlur: (event) => {
                  if (handleBlur) handleBlur(event);

                  handleSelfOnBlur(event);

                  const {
                    target: { value },
                  } = event;

                  if (value === '' || !value.trim() || needReset) {
                    setInput('');
                  }
                },
              })}
            />
            {showCloseButton && (
              <FontIcon
                iconName="close"
                className={styles.closeIcon}
                onClick={(event) => {
                  event.stopPropagation();
                  dispatch({
                    type: 'input',
                    input: '',
                  });
                  handleSelect();
                }}
              />
            )}
            <div className={styles.autocompleteContainer}>
              {suggestions.map((suggestion) => {
                const { active, description } = suggestion;

                const className = active ? styles.autocompleteItemActive : styles.autocompleteItem;

                return (
                  <div key={description} {...getSuggestionItemProps(suggestion, { className })}>
                    <span>{description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default GooglePlacesAutocomplete;
