import React from 'react';

import DialogueModal from 'components/DialogueModal';
import Button from 'components/Button';

import styles from './CohortCollisionsModal.scss';

const CohortCollisionsModal = ({ handleCloseClick }) => {
  return (
    <DialogueModal className={styles.dialogueModal}>
      <div className={styles.requestConfirmationModal}>
        <div className={styles.requestConfirmationContent}>Could not add candidate</div>
        <div className={styles.requestConfirmationButtons}>
          <Button onClick={handleCloseClick} warning>
            Close
          </Button>
        </div>
      </div>
    </DialogueModal>
  );
};

export default CohortCollisionsModal;
