import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash.isempty';

import { handleError } from 'utils/common';
import {
  promisePostAddEmployers,
  promisePostCohortEmployers,
} from 'pages/AdminPortal/ToolboxCohortEditorPage/promises';

import Block from 'components/Block';
import Button from 'components/Button';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import TableOverlay from 'components/TableOverlay';

import CohortCollisionsModal from '../CohortEditorCandidates/components/CohortCollisionsModal';
import SelectedEmployersTable from './components/SelectedEmployersTable';
import AddEmployersTable from './components/AddEmployersTable';

import styles from './CohortEditorEmployers.scss';

class CohortEditorEmployers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addingEmployers: false,
      showAddEmployersOptions: false,
    };
  }

  handleCollisionsSubmitClick = ({ candidatesToRemove }) => {
    const {
      cohort: { id: cohortId, attributes: { category } = {} } = {},
      actions: { cohortAddCandidatesWithRemovesStarted },
    } = this.props;

    this.setState({ collisionErrors: [] });

    const params = {
      candidatesToRemove,
      cohortId,
      category,
    };

    cohortAddCandidatesWithRemovesStarted(params);
  };

  handleAddAllEmployers = async () => {
    try {
      const {
        cohort: { id: cohortId } = {},
        actions: { nestedEntityLoadStarted },
      } = this.props;

      this.setState({
        addingEmployers: true,
        collisionErrors: [],
        showAddEmployersOptions: false,
      });

      await promisePostAddEmployers({ cohortId });

      nestedEntityLoadStarted({
        slice: 'toolboxCohorts',
        type: 'cohorts',
        id: cohortId,
        nestedType: 'cohort_employers',
        params: {
          page: 1,
          includeSet: 'employer',
          sortBy: 'employer_name_asc',
        },
      });

      this.setState({ addingEmployers: false });
    } catch (error) {
      handleError(error);
      const { response: { data: { errors } = {} } = {} } = error || {};

      this.setState({
        collisionErrors: errors,
        addingEmployers: false,
      });
    }
  };

  handleAddAddableEmployers = async () => {
    try {
      const {
        employers: { allIds = [], byId = {} } = {},
        cohort: { id: cohortId },
        actions: { nestedEntityLoadStarted },
      } = this.props;

      this.setState({
        addingEmployers: true,
        collisionErrors: [],
        showAddEmployersOptions: false,
      });

      const employersToAdd = allIds.filter((id) => {
        const {
          [id]: {
            attributes: { checked },
          },
        } = byId;
        return checked;
      });

      const promises = employersToAdd.map((employerId) =>
        promisePostCohortEmployers({
          cohortId,
          employerId,
          employerState: 'enabled',
        })
      );

      await Promise.all(promises);

      nestedEntityLoadStarted({
        slice: 'toolboxCohorts',
        type: 'cohorts',
        id: cohortId,
        nestedType: 'cohort_employers',
        params: {
          page: 1,
          includeSet: 'employer',
          sortBy: 'employer_name_asc',
        },
      });

      this.setState({ addingEmployers: false });
    } catch (error) {
      handleError(error);
      const { response: { data: { errors = [] } = {} } = {} } = error || {};

      this.setState({
        collisionErrors: errors,
        addingEmployers: false,
      });
    }
  };

  render() {
    const {
      cohortEmployersTotal,
      cohortEmployers,
      cohortEmployersLoading,
      employers,
      employersLoading,
      employersTotal,
      employersPage,
      cohort: {
        id: cohortId,
        attributes: { name, category, cohortSaving, locked, state } = {},
      } = {},
      loadMoreCohortEmployers,
      handleCohortEmployerRemove,
      handleCohortEmployerToggle,
      handleSetAllEmployersToggle,
      router,
      cohortLoading,
      modal,
      actions,
      actions: { showModal },
    } = this.props;

    const { collisionErrors, showAddEmployersOptions, addingEmployers } = this.state;

    const isReadOnly = locked;

    const savingContent = cohortSaving ? (
      <div className={styles.autoSaving}>Saving...</div>
    ) : (
      <div className={styles.autoSaved}>Cohort Saved</div>
    );

    const saveContent = cohortSaving === undefined ? null : savingContent;

    const titleBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: true,
      className: styles.titleBlock,
    };

    const backToModal =
      modal && router && router.location && router.location.state
        ? () =>
            showModal({
              key: modal.backKey,
              originalUrl: modal.originalUrl,
              parent: router.location.state.parent,
              route: modal.backPath,
            })
        : null;

    const backLinkProps = isEmpty(modal) ? {} : { onClick: backToModal };

    const backButtonProps = {
      quaternary: true,
      className: styles.backButton,
    };

    const backButton =
      modal.backPath && modal.backKey ? (
        <div className={styles.backButtonContainer}>
          <Link {...backLinkProps}>
            <Button {...backButtonProps}>&#60; Back</Button>
          </Link>
        </div>
      ) : null;

    const collisions =
      collisionErrors &&
      collisionErrors[0] &&
      collisionErrors[0].error &&
      collisionErrors[0].error.collisions
        ? collisionErrors[0].error.collisions
        : [];

    const collisionModalProps = {
      handleCloseClick: () => this.setState({ collisionErrors: [] }),
    };

    const collisionModal =
      collisions.length > 0 ? <CohortCollisionsModal {...collisionModalProps} /> : null;

    const selectedEmployersTableProps = {
      cohortId,
      isReadOnly,
      addingEmployers,
      cohortEmployers,
      cohortEmployersLoading,
      cohortEmployersTotal,
      employers,
      loadMoreCohortEmployers,
      handleCohortEmployerRemove,
      handleCohortEmployerToggle,
      handleSetAllEmployersToggle,
      handleShowAddEmployersOptions: () => this.setState({ showAddEmployersOptions: true }),
    };

    const addEmployersTableProps = {
      actions,
      cohortId,
      category,
      isReadOnly,
      addingEmployers,
      cohortEmployersTotal,
      employers,
      employersLoading,
      employersTotal,
      employersPage,
      handleAddAddableEmployers: this.handleAddAddableEmployers,
      handleHideAddEmployersOptions: () => this.setState({ showAddEmployersOptions: false }),
      handleAddAllEmployers: this.handleAddAllEmployers,
    };

    const tableContent = showAddEmployersOptions ? (
      <AddEmployersTable {...addEmployersTableProps} />
    ) : (
      <SelectedEmployersTable {...selectedEmployersTableProps} />
    );

    const overlay = cohortLoading ? <TableOverlay table="candidate" /> : null;

    const content = (
      <div className={styles.EditorEmployers}>
        {backButton}
        {overlay}
        <Block {...titleBlockProps}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              {name}
              <div className={styles.category}>
                {category === 'featured' ? 'Match' : 'Featured'}
              </div>
              <div className={state === 'active' ? styles.active : styles.inactive}>
                {state === 'active' ? 'Active' : 'Inactive'}
              </div>
            </div>
            <div className={styles.saveContent}>{saveContent}</div>
          </div>
        </Block>
        {tableContent}
        {collisionModal}
      </div>
    );

    const layoutWithoutSidebarProps = {
      content,
    };

    return <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />;
  }
}

export default CohortEditorEmployers;
