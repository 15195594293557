import { createStructuredSelector } from 'reselect';

import { getModal } from 'shared/store/app/selectors';

import {
  apiErrorsSelectorFactory,
  entitySelectorFactory,
  loadingSelectorFactory,
  pageSelectorFactory,
  totalSelectorFactory,
  savingSelectorFactory,
} from 'reduxStore/selectorFactories';

import {
  selectIsLoggedIn,
  selectIsAdmin,
  selectLogoutPending,
} from 'connectors/Login/redux/selectors';

import { selectUser } from 'connectors/User/redux/selectors';

import { selectPickerOptions, selectTags } from 'connectors/Defaults/redux/selectors';

import selectEmployer from './selectEmployer';
import selectEmployerErrors from './selectEmployerErrors';
import selectEmployee from './selectEmployee';
import selectEmployeeErrors from './selectEmployeeErrors';
import selectUserErrors from './selectUserErrors';

const selector = createStructuredSelector({
  apiErrors: apiErrorsSelectorFactory('toolboxEmployers'),
  candidates: entitySelectorFactory('toolboxEmployers', 'candidate'),
  candidateCompanyBlocks: entitySelectorFactory('toolboxEmployers', 'candidateCompanyBlock'),
  candidateCompanyBlocksPage: pageSelectorFactory('toolboxEmployers', 'candidateCompanyBlock'),
  candidateCompanyBlocksTotal: totalSelectorFactory('toolboxEmployers', 'candidateCompanyBlock'),
  candidateCompanyBlocksLoading: loadingSelectorFactory(
    'toolboxEmployers',
    'candidateCompanyBlock'
  ),
  cohorts: entitySelectorFactory('toolboxEmployers', 'cohort'),
  cohortEmployers: entitySelectorFactory('toolboxEmployers', 'cohortEmployer'),
  cohortEmployersPage: pageSelectorFactory('toolboxEmployers', 'cohortEmployer'),
  cohortEmployersTotal: totalSelectorFactory('toolboxEmployers', 'cohortEmployers'),
  cohortEmployersLoading: loadingSelectorFactory('toolboxEmployers', 'cohortEmployers'),
  isAdmin: selectIsAdmin,
  isLoggedIn: selectIsLoggedIn,
  isLoading: loadingSelectorFactory('toolboxEmployers', 'employer'),
  employers: entitySelectorFactory('toolboxEmployers', 'employer'),
  employees: entitySelectorFactory('toolboxEmployers', 'employee'),
  employeesPage: pageSelectorFactory('toolboxEmployers', 'employee'),
  employeesTotal: totalSelectorFactory('toolboxEmployers', 'employee'),
  employeesLoading: loadingSelectorFactory('toolboxEmployers', 'employee'),
  employer: selectEmployer,
  employee: selectEmployee,
  employerErrors: selectEmployerErrors,
  employeeErrors: selectEmployeeErrors,
  userErrors: selectUserErrors,
  logoutPending: selectLogoutPending,
  modal: getModal,
  page: pageSelectorFactory('toolboxEmployers', 'employer'),
  pickerOptions: selectPickerOptions,
  privateCandidateProfiles: entitySelectorFactory('toolboxEmployers', 'privateCandidateProfile'),
  rainmakersAdmins: entitySelectorFactory('toolboxEmployers', 'rainmakersAdmin'),
  accountManagers: entitySelectorFactory('toolboxEmployers', 'accountManager'),
  requisitionCandidates: entitySelectorFactory('toolboxEmployers', 'requisitionCandidate'),
  requisitionCandidatesPage: pageSelectorFactory('toolboxEmployers', 'requisitionCandidate'),
  requisitionCandidatesTotal: totalSelectorFactory('toolboxEmployers', 'requisitionCandidate'),
  requisitionCandidatesLoading: loadingSelectorFactory('toolboxEmployers', 'requisitionCandidate'),
  requisitionOpenings: entitySelectorFactory('toolboxEmployers', 'requisitionOpening'),
  saving: savingSelectorFactory('toolboxEmployers'),
  tags: selectTags,
  total: totalSelectorFactory('toolboxEmployers', 'employer'),
  user: selectUser,
  users: entitySelectorFactory('toolboxEmployers', 'user'),
});

export { selector as default };
