import React, { useContext, useEffect, useState } from 'react';
import isEmpty from 'lodash.isempty';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, useParams } from 'react-router-dom';

import { getEntity } from 'api/apiEntity';

import ReadyContainer from 'connectors/ReadyContainer';

import Sidebar from 'components/Sidebar';

import { AdminAuthHOC, ModalHOC } from 'containers/HOC';
import ConnectContainer from 'containers/ConnectContainer';

import {
  candidateInputChange,
  qAndAToggleArrayValue,
  qAndATextInputChange,
} from 'utils/formHelpers/CandidateHelpers';

import { handleError } from 'utils/common';
import { toggleArray } from 'utils/formUtils';

import duck from 'pages/AdminPortal/ToolboxCandidatesPage/redux';

import CandidateNonModalWrapper from 'pages/EmployeePortal/CandidateProfilePages/CandidateNonModalWrapper';

import { usePrevious } from 'hooks';

import { NotesContext } from './NotesContext';

import CandidateEditorEducation from './components/CandidateEditorEducation';
import CandidateEditorEmpty from './components/CandidateEditorEmpty';
import CandidateEditorIntro from './components/CandidateEditorIntro';
import CandidateEditorPersonal from './components/CandidateEditorPersonal';
import CandidateEditorSales from './components/CandidateEditorSales';
import CandidateEditorSettings from './components/CandidateEditorSettings';
import CandidateEditorSkills from './components/CandidateEditorSkills';
import CandidateEditorStats from './components/CandidateEditorStats';
import CandidateEditorWork from './components/CandidateEditorWork';
import { CandidateEditorNotes } from './components/CandidateEditorNotes';

const ToolboxCandidateEditor = (props) => {
  const {
    actions: {
      pickerOptionsLoadStarted,
      entityLoadStarted,
      entityLoadDone,
      nestedEntityLoadStarted,
      patchUserResource,
      patchResource,
    },
    candidate,
    candidate: { attributes: { temporary = false, fullName } = {} } = {},
    candidate: { relationships: { user: { data: userIds = [] } = {} } = {} } = {},
  } = props;

  const params = useParams();
  const { id, view } = params;
  const prevId = usePrevious(id);

  const { enableNotes, showNotes, setShowNotes } = useContext(NotesContext);

  useEffect(() => {
    if (!temporary) {
      doLoad(id);
    }

    pickerOptionsLoadStarted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const nextId = id;
    const currId = prevId;

    if (nextId !== currId) {
      doLoad(nextId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const doLoad = async (id) => {
    const args = {
      type: 'candidates',
      config: {
        params: {
          includeSet: 'admin_search',
          normalizeIt: true,
        },
      },
      id,
    };

    let data;
    try {
      ({ data } = await getEntity(args));
    } catch (error) {
      handleError(error);
    }

    const {
      entities: {
        candidate: { byId = {}, allIds = [] } = {},
        user: { byId: usersById = {} } = {},
      } = {},
    } = data;

    const {
      [allIds[0]]: {
        attributes: { rainmakersAdminId } = {},
        relationships: { user: { data: userIds = [] } = {} } = {},
      } = {},
    } = byId || {};

    const { attributes: { referredByUserId } = {} } = usersById[userIds[0]] || {};

    entityLoadDone({
      data,
      type: 'candidates',
      slice: 'toolboxCandidates',
      updateOnly: true,
    });

    nestedEntityLoadStarted({
      type: 'candidates',
      id,
      nestedType: 'work_histories',
      slice: 'toolboxCandidates',
      params: {
        sortBy: 'end_date_desc_nulls_first,start_date_desc',
        includeSet: 'company_sales_periods',
      },
    });

    nestedEntityLoadStarted({
      type: 'candidates',
      id,
      nestedType: 'candidate_educations',
      slice: 'toolboxCandidates',
      params: {
        sortBy: 'finished_at_desc_nulls_first,started_at_desc',
        includeSet: 'education_organization',
      },
    });

    nestedEntityLoadStarted({
      type: 'candidates',
      id,
      nestedType: 'candidate_requirement',
      slice: 'toolboxCandidates',
    });

    nestedEntityLoadStarted({
      type: 'candidates',
      id,
      nestedType: 'private_candidate_profile',
      slice: 'toolboxCandidates',
    });

    nestedEntityLoadStarted({
      id,
      nestedType: 'vimeo_video',
      slice: 'toolboxCandidates',
      type: 'candidates',
      updateOnly: true,
    });

    entityLoadStarted({
      type: 'requisition_candidates',
      slice: 'toolboxCandidates',
      params: {
        page: 1,
        candidateId: id,
        sortBy: 'created_at_desc',
        includeSet: 'admin_toolbox',
        state: ['active', 'candidate_pass_pending'],
      },
    });

    entityLoadStarted({
      type: 'companies',
      typeOverride: 'bookmarks',
      slice: 'toolboxCandidates',
      params: {
        page: 1,
        bookmarkedCandidateId: id,
      },
    });

    entityLoadStarted({
      type: 'candidate_company_blocks',
      slice: 'toolboxCandidates',
      params: {
        page: 1,
        candidateId: id,
        sortBy: 'created_at_desc',
        includeSet: 'company_creator',
      },
    });

    entityLoadStarted({
      type: 'rainmakers_admins',
      typeOverride: 'talent_concierges',
      slice: 'toolboxCandidates',
      params: {
        page: 1,
        includeSet: 'user',
        talentConcierge: true,
        sortBy: 'first_name_asc,last_name_asc',
      },
    });

    if (rainmakersAdminId) {
      entityLoadStarted({
        type: 'rainmakers_admins',
        typeOverride: 'talent_concierges',
        slice: 'toolboxCandidates',
        id: rainmakersAdminId,
        params: {
          includeSet: 'user',
          state: false,
        },
        updateOnly: true,
      });
    }

    if (referredByUserId) {
      entityLoadStarted({
        type: 'users',
        slice: 'toolboxCandidates',
        id: referredByUserId,
        updateOnly: true,
        params: {
          includeSet: 'current_profile',
        },
      });
    }

    const cohortCandidatesArgs = {
      type: 'cohort_candidates',
      config: {
        params: {
          page: 1,
          candidateId: id,
          cohortCategory: ['search', 'featured'],
          cohortState: ['open', 'active'],
          cohortVirtual: false,
          includeSet: 'cohort',
          sortBy: 'created_at',
          normalizeIt: true,
        },
      },
    };

    let cohortCandidatesData;
    try {
      ({ data: cohortCandidatesData } = await getEntity(cohortCandidatesArgs));
    } catch (error) {
      handleError(error);
    }

    entityLoadDone({
      data: cohortCandidatesData,
      type: 'cohort_candidates',
      slice: 'toolboxCandidates',
    });
  };

  const handlePatchResource = (attributes, type) => {
    patchUserResource({
      attributes,
      id: type === 'users' ? userIds[0] : id,
      slice: 'toolboxCandidates',
      type,
    });
  };

  const handleUserInputChange = (event) => {
    const {
      target: { name, value },
    } = event;

    handlePatchResource({ [name]: value }, 'users');
  };

  const handleCandidateInputChange = (event) => {
    const { candidate: { attributes: candidate = {} } = {} } = props;

    const { name, newValue } = candidateInputChange(event, candidate);

    handlePatchResource({ [name]: newValue }, 'candidates');
  };

  const handlePrivateInputChange = (event) => {
    const {
      target: { type, name, value, checked },
    } = event;

    const newValue = type === 'checkbox' ? checked : value;

    const {
      actions: { patchResource },
      privateCandidateProfiles: { byId = {} } = {},
      match: {
        params: { id: candidateId },
      },
    } = props;

    const id = Object.keys(byId).find((id) => {
      const { [id]: { attributes: { candidateId: privCandidateId } = {} } = {} } = byId;
      return privCandidateId === candidateId;
    });

    patchResource({
      type: 'private_candidate_profiles',
      slice: 'toolboxCandidates',
      id,
      attributes: {
        [name]: newValue,
      },
    });
  };

  const handleExperienceInputChange = (event) => {
    const {
      target: { years, months, name },
    } = event;

    const realYears = years === '' || years === null ? 0 : parseInt(years, 10);
    const realMonths = months === '' || months === null ? 0 : parseInt(months, 10);

    const expInMonths = realYears * 12 + realMonths;

    handlePatchResource({ [name]: expInMonths }, 'candidates');
  };

  const handleSalesPeriodInputChange = ({ event, salesPeriodId, type }) => {
    const {
      target: { name, value: newValue },
    } = event;

    const types = {
      AESalesPeriod: 'ae_sales_periods',
      CSMSalesPeriod: 'csm_sales_periods',
    };

    const { [type]: patchType } = types;

    patchResource({
      type: patchType,
      slice: 'toolboxCandidates',
      id: salesPeriodId,
      attributes: {
        [name]: newValue,
      },
    });
  };

  const handleToggleArray = (event) => {
    const {
      target: { subCategories, name, value, optionType },
    } = event;

    const {
      candidate: {
        relationships: { candidateRequirement: { data: candidateRequirementIds = [] } = {} } = {},
      },
      candidateRequirements: { byId: candidateRequirementsById = {} },
    } = props;

    const { attributes: { [name]: arrayToUpdate = [] } = {} } =
      candidateRequirementsById[candidateRequirementIds[0]];

    if (subCategories && optionType === 'folder') {
      let newArray = arrayToUpdate.slice();

      const subCatValues = subCategories.map((sub) => `${sub.value[sub.value.type]}`);

      const filterValues = newArray.map((val) => `${val[val.type]}`);

      const allSelected = subCatValues.every((val) => filterValues.includes(val));

      if (allSelected) {
        newArray = newArray.filter((val) => `${val[val.type]}` !== `${value[value.type]}`);
        newArray = newArray.filter(
          (val) =>
            !subCategories.map((sub) => `${sub.value[sub.value.type]}`).includes(`${val[val.type]}`)
        );
      } else {
        subCategories.forEach((subCat) => {
          if (!filterValues.includes(`${subCat.value[subCat.value.type]}`)) {
            newArray.push(subCat.value);
          }
        });
      }

      return patchResource({
        attributes: {
          [name]: newArray,
        },
        id: candidateRequirementIds[0],
        slice: 'toolboxCandidates',
        type: 'candidate_requirements',
      });
    }

    if (arrayToUpdate.map((val) => val[val.type]).includes(value[value.type])) {
      let newArray = arrayToUpdate.slice();

      newArray = arrayToUpdate.filter((val) => `${val[val.type]}` !== `${value[value.type]}`);

      return patchResource({
        attributes: {
          [name]: newArray,
        },
        id: candidateRequirementIds[0],
        slice: 'toolboxCandidates',
        type: 'candidate_requirements',
      });
    }
    const newArray = arrayToUpdate.slice();

    newArray.push(value);

    if (subCategories && optionType === 'folder') {
      subCategories.forEach((subCat) => {
        if (!newArray.map((val) => val[val.type]).includes(subCat.label)) {
          newArray.push(subCat.value);
        }
      });
    }

    return patchResource({
      attributes: {
        [name]: newArray,
      },
      id: candidateRequirementIds[0],
      slice: 'toolboxCandidates',
      type: 'candidate_requirements',
    });
  };

  const handleToggleRegions = (event) => {
    const {
      target: { name, label: region },
    } = event;

    const {
      candidate: {
        relationships: { candidateRequirement: { data: candidateRequirementIds = [] } = {} } = {},
      },
      candidateRequirements: { byId: candidateRequirementsById = {} },
    } = props;

    // eslint-disable-next-line no-unused-vars
    const { attributes: { [name]: arrayToUpdate = [] } = {} } =
      candidateRequirementsById[candidateRequirementIds[0]];

    if (region) {
      const regValue = region;
      // Multiselect:
      // const newArray = arrayToUpdate.map((item) => item.region).includes(regValue)
      //   ? arrayToUpdate.filter((r) => r.region !== regValue)
      //   : [...arrayToUpdate, { type: 'region', region: regValue }];

      // Single select:
      const newArray = arrayToUpdate.map((item) => item.region).includes(regValue)
        ? []
        : [{ type: 'region', region: regValue }];

      console.log('newArray', newArray);

      return patchResource({
        attributes: {
          [name]: newArray,
        },
        id: candidateRequirementIds[0],
        slice: 'toolboxCandidates',
        type: 'candidate_requirements',
      });
    }
  };

  const handleCandReqInputChange = (event) => {
    const {
      target: { name, value, checked, type },
    } = event;

    const newValue = type === 'checkbox' ? checked : value;

    const {
      actions: { patchResource },
      candidate: {
        relationships: { candidateRequirement: { data: candidateRequirementIds = [] } = {} } = {},
      },
    } = props;

    patchResource({
      attributes: {
        [name]: newValue,
      },
      id: candidateRequirementIds[0],
      slice: 'toolboxCandidates',
      type: 'candidate_requirements',
    });
  };

  const handleCandReqRoleChange = (event) => {
    const {
      target: {
        value: { role: value },
        name,
      },
    } = event;

    const {
      actions: { patchResource },
      candidate: {
        relationships: { candidateRequirement: { data: candidateRequirementIds = [] } = {} } = {},
      },
      candidateRequirements: { byId: candidateRequirementsById = {} },
    } = props;

    const { attributes: candReq = {} } = candidateRequirementsById[candidateRequirementIds[0]];

    const { [name]: array } = candReq;

    const newArray = toggleArray({ array, value });

    patchResource({
      attributes: {
        [name]: newArray,
      },
      id: candidateRequirementIds[0],
      slice: 'toolboxCandidates',
      type: 'candidate_requirements',
    });
  };

  const handleCandReqWorkModelChange = (event) => {
    const {
      target: {
        value: { workModel: value },
        name,
      },
    } = event;

    const {
      actions: { patchResource },
      candidate: {
        relationships: { candidateRequirement: { data: candidateRequirementIds = [] } = {} } = {},
      },
      candidateRequirements: { byId: candidateRequirementsById = {} },
    } = props;

    console.log('props', props);

    const { attributes: candReq = {} } = candidateRequirementsById[candidateRequirementIds[0]];

    const { [name]: array } = candReq;

    console.log(name, array, value);

    const newArray = toggleArray({ array, value });

    patchResource({
      attributes: {
        [name]: newArray,
      },
      id: candidateRequirementIds[0],
      slice: 'toolboxCandidates',
      type: 'candidate_requirements',
    });
  };

  const handleQAndATextInputChange = ({ event, idx }) => {
    const {
      candidate: { attributes: { qAndA } = {} },
    } = props;

    const { name, newValue } = qAndATextInputChange(event, qAndA, idx);

    handlePatchResource({ [name]: newValue }, 'candidates');
  };

  const handleQAndAToggleArrayValue = ({ event, idx }) => {
    const { candidate: { attributes: { qAndA } = {} } = {} } = props;

    const { name, newValue } = qAndAToggleArrayValue(event, qAndA, idx);

    handlePatchResource({ [name]: newValue }, 'candidates');
  };

  const { router } = props;

  const newProps = {
    ...props,
    key: id,
    match: { params },
    handleCandidateInputChange,
    handlePrivateInputChange,
    handleCandReqInputChange,
    handleCandReqRoleChange,
    handleCandReqWorkModelChange,
    handleToggleArray,
    handleToggleRegions,
    handleExperienceInputChange,
    handleQAndAToggleArrayValue,
    handleQAndATextInputChange,
    handleSalesPeriodInputChange,
    handleUserInputChange,
  };

  const sidebarProps = {
    router,
    type: 'toolboxCandidateEditor',
    page: view,
    id,
  };

  if (isEmpty(candidate)) {
    return (
      <>
        <Helmet key="helmet" title="Candidate Editor" />
        <CandidateEditorEmpty {...props} />
      </>
    );
  }

  return (
    <>
      <Helmet key="helmet" title="Candidate Editor" />
      <ReadyContainer scroll key="readyContainer">
        <Sidebar {...sidebarProps} />
        <Switch>
          <Route
            exact
            path={'/toolbox/candidate/:id/edit-preview'}
            component={CandidateNonModalWrapper}
          />
          <Route exact path={'/toolbox/candidate/:id/edit-personal'}>
            <CandidateEditorPersonal {...newProps} />
          </Route>
          <Route exact path={'/toolbox/candidate/:id/edit-work'}>
            <CandidateEditorWork {...newProps} />
          </Route>
          <Route exact path={'/toolbox/candidate/:id/edit-sales'}>
            <CandidateEditorSales {...newProps} />
          </Route>
          <Route exact path={'/toolbox/candidate/:id/edit-education'}>
            <CandidateEditorEducation {...newProps} />
          </Route>
          <Route exact path={'/toolbox/candidate/:id/edit-skills'}>
            <CandidateEditorSkills {...newProps} />
          </Route>
          <Route exact path={'/toolbox/candidate/:id/edit-stats'}>
            <CandidateEditorStats {...newProps} />
          </Route>
          <Route exact path={'/toolbox/candidate/:id/edit-settings'}>
            <CandidateEditorSettings {...newProps} />
          </Route>
          <Route exact path={'/toolbox/candidate/:id/edit-intro'}>
            <CandidateEditorIntro {...newProps} />
          </Route>
        </Switch>
        <CandidateEditorNotes
          candidateId={id}
          fullName={fullName}
          showEditor={enableNotes && showNotes}
          setShowNotes={setShowNotes}
        />
      </ReadyContainer>
    </>
  );
};

const InternalComponent = ConnectContainer(duck)(
  AdminAuthHOC()(
    ModalHOC({
      paginatorUrl: '/toolbox/candidate/:id/edit-preview',
    })(ToolboxCandidateEditor)
  )
);

const ExternalComponent = (props) => {
  const [showNotes, setShowNotes] = useState(false);

  return (
    <NotesContext.Provider
      value={{
        enableNotes: true,
        showNotes,
        setShowNotes,
      }}
    >
      <InternalComponent {...props} />
    </NotesContext.Provider>
  );
};

export default ExternalComponent;
