import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import hash from 'object-hash';

import { handleError } from 'utils/common';
import { trackEvent } from 'utils/analytics';
import { segmentSearchEmployee } from 'segment/eventNames';

import { promiseBookmarks } from 'pages/EmployeePortal/CandidateBookmarksPage/promises';

import { selectIsAdmin } from 'connectors/Login/redux/selectors';

import { promiseSearch } from '../../promises';

import { RESULTS_LOAD_STARTED } from '../actions';

import { resultsLoadDone } from '../creators';

const trackSearch = (searchProperties, resultsCount) => {
  const { trackProperties = {}, keywords = [], savedSearchId = null } = searchProperties || {};

  const event = segmentSearchEmployee;

  const properties = {
    ...trackProperties,
    hashId: hash(trackProperties),
    keywords,
    resultsCount,
    savedSearchId,
  };

  const eventObject = { event, properties };

  trackEvent(eventObject);
};

const searchCandidates = function* searchCandidates(action) {
  const {
    payload: { allSearchParams, noTrack = false, searchProperties, latestCandidateId },
  } = action;

  const sortSearchAfterFilter = latestCandidateId
    ? `&filter[sort_search_after]=${latestCandidateId}`
    : '';

  const promiseParams = {
    allSearchParams: `${allSearchParams}${sortSearchAfterFilter}`,
    category: 'search',
    contextOrigin: 'search_view',
  };

  try {
    const {
      data,
      data: {
        entities: { candidate: { allIds: candIds = [] } = {} } = {},
        meta: { pagination: { totalCount } = {} } = {},
      } = {},
    } = yield call(promiseSearch, promiseParams);

    const bookmarksParams = {
      candidateIds: candIds,
    };

    const [{ bookmarks = [] }] = yield all([call(promiseBookmarks, bookmarksParams)]);

    const bookmarkCandIds = bookmarks.map((bm) => bm.candidateId);

    candIds.forEach((candId, index) => {
      const isNew = false;

      if (bookmarkCandIds.includes(candId)) {
        data.entities.candidate.byId[candId] = {
          ...data.entities.candidate.byId[candId],
          attributes: {
            ...data.entities.candidate.byId[candId].attributes,
            indexPosition: index,
            bookmark: bookmarks.find((bm) => bm.candidateId === candId),
            isNew,
          },
        };
      }
    });

    yield put(resultsLoadDone({ data, type: 'candidate' }));

    yield call(delay, 8000);

    const isAdmin = yield select(selectIsAdmin);

    if (!noTrack && !isAdmin) {
      yield call(trackSearch, searchProperties, totalCount);
    }
  } catch (error) {
    handleError(error);
  }
};

const sagaSearchCandidates = function* sagaSearchCandidates() {
  yield takeLatest(RESULTS_LOAD_STARTED, searchCandidates);
};

export default sagaSearchCandidates;
