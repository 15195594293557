import { createStructuredSelector } from 'reselect';

import { getModal } from 'shared/store/app/selectors';

import {
  apiErrorsSelectorFactory,
  entitySelectorFactory,
  loadingSelectorFactory,
  pageSelectorFactory,
  totalSelectorFactory,
  savingSelectorFactory,
} from 'reduxStore/selectorFactories';

import {
  selectIsLoggedIn,
  selectIsAdmin,
  selectLogoutPending,
} from 'connectors/Login/redux/selectors';

import { selectUser } from 'connectors/User/redux/selectors';

import { selectPickerOptions } from 'connectors/Defaults/redux/selectors';

import selectCandidate from './selectCandidate';
import selectCandidateErrors from './selectCandidateErrors';
import selectUserErrors from './selectUserErrors';

const selector = createStructuredSelector({
  aeSalesPeriod: entitySelectorFactory('toolboxCandidates', 'aeSalesPeriod'),
  csmSalesPeriod: entitySelectorFactory('toolboxCandidates', 'csmSalesPeriod'),
  apiErrors: apiErrorsSelectorFactory('toolboxCandidates'),
  candidate: selectCandidate,
  candidates: entitySelectorFactory('toolboxCandidates', 'candidate'),
  candidateCompanyBlocks: entitySelectorFactory('toolboxCandidates', 'candidateCompanyBlock'),
  candidateCompanyBlocksPage: pageSelectorFactory('toolboxCandidates', 'candidateCompanyBlock'),
  candidateCompanyBlocksTotal: totalSelectorFactory('toolboxCandidates', 'candidateCompanyBlock'),
  candidateCompanyBlocksLoading: loadingSelectorFactory(
    'toolboxCandidates',
    'candidateCompanyBlock'
  ),
  candidateErrors: selectCandidateErrors,
  candidateEducations: entitySelectorFactory('toolboxCandidates', 'candidateEducation'),
  candidateRequirements: entitySelectorFactory('toolboxCandidates', 'candidateRequirement'),
  cohorts: entitySelectorFactory('toolboxCandidates', 'cohort'),
  cohortCandidates: entitySelectorFactory('toolboxCandidates', 'cohortCandidate'),
  cohortCandidatesPage: pageSelectorFactory('toolboxCandidates', 'cohortCandidate'),
  cohortCandidatesTotal: totalSelectorFactory('toolboxCandidates', 'cohortCandidates'),
  cohortCandidatesLoading: loadingSelectorFactory('toolboxCandidates', 'cohortCandidates'),
  companies: entitySelectorFactory('toolboxCandidates', 'company'),
  bookmarks: entitySelectorFactory('toolboxCandidates', 'bookmark'),
  bookmarksPage: pageSelectorFactory('toolboxCandidates', 'bookmark'),
  bookmarksTotal: totalSelectorFactory('toolboxCandidates', 'bookmark'),
  bookmarksLoading: loadingSelectorFactory('toolboxCandidates', 'bookmark'),
  educationOrganizations: entitySelectorFactory('toolboxCandidates', 'educationOrganization'),
  employers: entitySelectorFactory('toolboxCandidates', 'employer'),
  employees: entitySelectorFactory('toolboxCandidates', 'employee'),
  pickerOptions: selectPickerOptions,
  privateCandidateProfiles: entitySelectorFactory('toolboxCandidates', 'privateCandidateProfile'),
  privateReqCanFeedback: entitySelectorFactory('toolboxCandidates', 'privateReqCanFeedback'),
  rainmakersAdmins: entitySelectorFactory('toolboxCandidates', 'rainmakersAdmin'),
  talentConcierges: entitySelectorFactory('toolboxCandidates', 'talentConcierge'),
  requisitionCandidates: entitySelectorFactory('toolboxCandidates', 'requisitionCandidate'),
  requisitionCandidatesPage: pageSelectorFactory('toolboxCandidates', 'requisitionCandidate'),
  requisitionCandidatesTotal: totalSelectorFactory('toolboxCandidates', 'requisitionCandidate'),
  requisitionCandidatesLoading: loadingSelectorFactory('toolboxCandidates', 'requisitionCandidate'),
  requisitionOpenings: entitySelectorFactory('toolboxCandidates', 'requisitionOpening'),
  requisitionOpeningsPage: pageSelectorFactory('toolboxCandidates', 'requisitionOpening'),
  requisitionOpeningsTotal: totalSelectorFactory('toolboxCandidates', 'requisitionOpening'),
  requisitionOpeningsLoading: loadingSelectorFactory('toolboxCandidates', 'requisitionOpening'),
  users: entitySelectorFactory('toolboxCandidates', 'user'),
  vimeoVideos: entitySelectorFactory('toolboxCandidates', 'vimeoVideo'),
  workHistories: entitySelectorFactory('toolboxCandidates', 'workHistory'),
  logoutPending: selectLogoutPending,
  modal: getModal,
  isAdmin: selectIsAdmin,
  isLoading: loadingSelectorFactory('toolboxCandidates', 'candidate'),
  isLoggedIn: selectIsLoggedIn,
  page: pageSelectorFactory('toolboxCandidates', 'candidate'),
  total: totalSelectorFactory('toolboxCandidates', 'candidate'),
  saving: savingSelectorFactory('toolboxCandidates'),
  user: selectUser,
  userErrors: selectUserErrors,
});

export { selector as default };
