import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import AutocompleteServer from 'connectors/AutocompleteServer';

import Block from 'components/Block';
import FormattedName from 'components/FormattedName';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import CandidateData from 'components/CandidateData';

import PermBlockActionDropdown from '../PermBlockActionDropdown';

import styles from './BlockedSettings.scss';

const BlockedSettings = (props) => {
  const {
    actions: { deleteResource },
    candidates: { byId: candidatesById = {} } = {},
    candidateCompanyBlocks: { allIds: ccBlocksAllIds = [], byId: ccBlocksById = {} },
    candidateCompanyBlocksTotal,
    employer: { attributes: { applicationStatus } = {} } = {},
    handlePermBlockChange,
    loadMoreCandidateCompanyBlocks,
  } = props;

  const globalBlocksBlockProps = {
    addWhiteBG: true,
    largeTitleFont: true,
    title: 'Global Blocks',
    noTitleBorder: true,
    addPadding: true,
    titleClass: styles.titlePadding,
  };

  const permBlocksBlockProps = {
    addWhiteBG: true,
    largeTitleFont: true,
    title: 'Permanent Blocks',
    noTitleBorder: true,
    addPadding: true,
    titleClass: styles.titlePadding,
  };

  const permBlocksInputProps = {
    placeholder: 'Enter candidate name and select to create a block',
    value: '',
    size: 'xlarge',
    name: 'candidate_id',
    handleInputChange: handlePermBlockChange,
    hasLogo: true,
    autocompleteType: 'candidates',
    field: 'name',
    needReset: true,
  };

  const permBlockCompanies = ccBlocksAllIds.map((id) => {
    const {
      attributes: { ownerType, blockType, creatorType },
      relationships: {
        candidate: { data: candidateIds = [] } = {},
        creator: { data: creatorIds = [], type: creatorTypes = [] } = {},
      } = {},
    } = ccBlocksById[id] || {};

    const creatorTypePlural = `${creatorTypes[0]}s`;

    const { [creatorTypePlural]: { byId: creatorsById = {} } = {} } = props;

    const { attributes: { firstName, lastName } = {} } = creatorsById[creatorIds[0]] || {};

    const permBlockActionDropdownProps = {
      slice: 'toolboxEmployers',
      candidateCompanyBlockId: id,
      deleteResource,
    };

    const candidateDataProps = {
      candidate: candidatesById[candidateIds[0]],
    };

    const ownerContent = ownerType === 'RainmakersAdmin' ? 'Admin' : ownerType;

    const nameProps = {
      firstName,
      lastName,
    };

    const candidateContent = (
      <div className={styles.candidateContent}>
        <CandidateData {...candidateDataProps} />
      </div>
    );

    return (
      <div key={id} className={styles.empContent}>
        {candidateContent}
        <div className={styles.blockerType}>
          {ownerContent} ({blockType})
        </div>
        <div className={styles.blockerType}>{creatorType || 'System'}</div>
        <div className={styles.blockerType}>
          <FormattedName {...nameProps} />
        </div>
        <div className={styles.actionContent}>
          <PermBlockActionDropdown {...permBlockActionDropdownProps} />
        </div>
      </div>
    );
  });
  const permBlocksInfiniteScrollProps = {
    pageStart: 0,
    loadMore: loadMoreCandidateCompanyBlocks,
    hasMore: candidateCompanyBlocksTotal > ccBlocksAllIds.length,
    loader: <InfiniteScrollLoader />,
    useWindow: false,
  };

  const blockedByAppStatus = applicationStatus === 'pending';

  const isGloballyBlocked = blockedByAppStatus || false;

  const globalBlockText = isGloballyBlocked ? (
    <span className={styles.warning}>Employer is globally blocked</span>
  ) : (
    <span className={styles.info}>Employer is not globally blocked</span>
  );

  return (
    <div className={styles.activeContent}>
      <Block {...globalBlocksBlockProps}>
        <div className={styles.globalBlocksContainer}>
          {globalBlockText}
          <div className={styles.blockedStatuses}>
            <span>Application Status:</span>{' '}
            <span className={styles.subtext}>{applicationStatus}</span>
          </div>
        </div>
      </Block>
      <Block {...permBlocksBlockProps}>
        <div className={styles.permBlocksContainer}>
          <AutocompleteServer {...permBlocksInputProps} />
        </div>
        <div className={styles.tempBlocksHeader}>
          <div className={styles.companyHeader}>Candidate</div>
          <div className={styles.checkboxHeader}>Owner (type)</div>
          <div className={styles.checkboxHeader}>Creator</div>
          <div className={styles.checkboxHeader}>Creator Name</div>
          <div className={styles.cohortActionHeader} />
        </div>
        <div className={styles.companiesContainer}>
          <InfiniteScroll {...permBlocksInfiniteScrollProps}>{permBlockCompanies}</InfiniteScroll>
        </div>
      </Block>
    </div>
  );
};

export default BlockedSettings;
